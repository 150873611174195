import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageFluid from "../components/image-fluid"

const IndexPage = ({ intl }) => (
    <Layout>
      <SEO lang={intl.locale}
           title={intl.formatMessage({ id: "pages.home.title" })}
           description={intl.formatMessage({ id: "description" })}
      />
      <div className={`homepage`}>
        <div className={`logo`}>
          <ImageFluid alt={``} filename={`airmink-logo.png`} />
        </div>
        <div className={`intro`}>
          <h1 className={`title`}>{intl.formatMessage({ id: "pages.home.subtitle" })}</h1>
          <h2 className={`subtitle`} dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "pages.home.description" })}}></h2>
        </div>

        <a href={`https://app.airmink.com`} className={`btn btn-white btn-lg cta`}>{intl.formatMessage({ id: "pages.home.cta" })}</a>

        <div className={`box`}>
          <div className={`works-with`}>{intl.formatMessage({ id: "pages.home.works" })}</div>
          <div className={`browsers`}>
            <ImageFluid alt={``} filename={`browsers.png`} />
          </div>
          <h3 className={`title`}>{intl.formatMessage({ id: "pages.home.about.title" })}</h3>
          <p className={`text`} dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "pages.home.about.text" })}}></p>
        </div>
      </div>
    </Layout>
)

export default injectIntl(IndexPage)
